<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="items.items"
      item-key="id"
      class="elevation-0 background-transparent"
      :loading="loading"
      :options.sync="options"
      :server-items-length="items.meta.totalItems"
      :footer-props="{
        'items-per-page-options': rowsPerPageItemsGlobal
      }"
    >
      <template v-slot:top>
        <div class="d-flex justify-space-between align-center pr-2" style="width: 100%">
          <div class="d-flex align-center pb-3">
            <div>
            </div>
            <div class="align-center pl-2">
              <span class="text-body-2 color-secondary">{{ $lang.hints.dataRefreshingEveryMinute }}</span>
            </div>
          </div>
          <div class="d-flex align-center">
            <v-col cols="4" class="pr-2 py-0">
              <v-select
                v-model="filterInMilliseconds"
                :items="filterInMillisecondsList"
                :label="$lang.labels.activeIn"
                dense
                outlined
                clearable
                item-text="text"
                item-value="value"
                data-cy="instances-filter"
              ></v-select>
            </v-col>
            <v-col cols="7" style="padding: 0 !important;">
              <v-text-field
                v-model="name"
                outlined
                dense
                clearable
                :label="$lang.labels.name"
                data-cy="instances-name"
              ></v-text-field>
            </v-col>
            <div class="pb-4 ml-2">
              <v-btn
                text
                light
                color="primary"
                style="padding: 0 !important;"
                @click="sendQuery()"
              ><v-icon
                dense
                small
              >mdi-refresh</v-icon></v-btn>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:item.id="{ item }">
        <div class="font-weight-bold"># {{ item.id }}</div>
      </template>

      <template v-slot:item.type="{ item }">
        <div>{{ $lang.status[item.type] }}</div>
      </template>

      <template v-slot:item.ipAddressesArray="{ item }">
        <template v-for="ip in item.ipAddressesArray">
          <div :key="ip">{{ ip }}</div>
        </template>
      </template>

      <template v-slot:item.createdOn="{ item }">
        <div>{{ item.createdOn | formatDateTimePrecise }}</div>
      </template>

      <template v-slot:item.heartbeat="{ item }">
        <div>{{ item.heartbeat | formatDateTimePrecise }}</div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-row no-gutters class="d-flex justify-end" style="min-width: 250px;">
          <v-btn
            color="primary"
            class="mx-0 mr-1"
            :to="{ name: 'logInstanceStats', params: { id: item.id } }"
          >
            {{ $lang.actions.stats }}
          </v-btn>
          <v-btn
            color="primary"
            class="mx-0"
            :to="{ name: 'logs-internal-jobs', params: { instanceName: item.name } }"
          >{{ $lang.actions.internalJobs }}
          </v-btn>
        </v-row>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="snackShow"
      :color="snackColor"
      content-class="text-center"
      top
    >
      <span class="color-accent-text">{{ snackbarText }}</span>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '@/main'
import { debounce } from 'lodash'
import { name } from '../../lang/de/labels'

export default {
  props: {
    items: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      snackbarText: '',
      snackShow: false,
      snackColor: 'success',
      options: {},
      totalItems: 0,
      headers: [
        {
          text: this.$lang.labels.name,
          align: 'start',
          sortable: true,
          value: 'name'
        },
        { text: this.$lang.labels.type, value: 'type' },
        { text: this.$lang.labels.ipAddresses, value: 'ipAddressesArray' },
        { text: this.$lang.labels.createdOn, value: 'createdOn' },
        { text: this.$lang.labels.heartbeat, value: 'heartbeat' },
        { text: this.$lang.labels.actions, value: 'actions', align: 'end', sortable: false }
      ],
      name: '',
      lock: true,
      stats: null,
      heartbeat: '',
      statsShow: false,
      filterInMilliseconds: null,
      filterInMillisecondsList: [
        { value: 60000, text: '1 min' },
        { value: 300000, text: '5 min' },
        { value: 900000, text: '15 min' },
        { value: 1800000, text: '30 min' },
        { value: 3600000, text: '1 hour' },
        { value: 86400000, text: '1 day' },
        { value: null, text: this.$lang.labels.all }
      ],
      refreshInterval: null
    }
  },
  computed: {
    ...mapState('app', ['rowsPerPageItemsGlobal', 'userSettings'])
  },
  watch: {
    options: {
      handler () {
        if (!this.lock && !this.loading) this.sendQuery()
      },
      deep: true
    },
    name: {
      handler: debounce( function (val) {
        if (!this.lock && !this.loading) this.sendQuery()
      }, 500)
    },
    filterInMilliseconds: {
      handler () {
        if (!this.lock && !this.loading) this.sendQuery()
      }
    }
  },
  mounted() {
    bus.$on('refreshData', (name) => {
      if (!this.lock && !this.loading && name === this.$route.name) this.sendQuery()
    })
  },
  created() {

    if (this.userSettings.display.showId) {
      this.headers.splice(0, 0, { text: this.$lang.header.id, value: 'id' })
    }

    if (this.$route && this.$route.query && this.$route.query.name) {
      this.lock = true
      this.name = this.$route.query.name
      this.loadPreFill()
    } else {
      if (localStorage.preFillData) {
        const preFill = JSON.parse(localStorage.preFillData)

        if (preFill && preFill.logInstancesList) {
          this.lock = true
          this.name = preFill.logInstancesList.name
          this.filterInMilliseconds = preFill.logInstancesList.filterInMilliseconds
          this.options = preFill.logInstancesList.options
          this.loadPreFill()
        } else {
          this.loadPreFill()
        }
      } else {
        this.loadPreFill()
      }
    }
    this.refreshInterval = setInterval(() => {
      if (!this.lock) this.sendQuery()
    }, 60000)
  },
  beforeDestroy() {
    clearInterval(this.refreshInterval)
  },
  methods: {
    showSnack(text, color = 'success') {
      this.snackbarText = text
      this.snackColor = color
      this.snackShow = true
    },
    savePreFill() {
      if (!localStorage.preFillData) localStorage.preFillData = '{}'
      if (localStorage.preFillData) {
        const preFill = JSON.parse(localStorage.preFillData)

        if (!preFill.logInstancesList) preFill.logInstancesList = {
          name: '',
          filterInMilliseconds: null,
          options: {
            page: 1,
            itemsPerPage: 25,
            sortBy: [],
            sortDesc: []
          }
        }

        preFill.logInstancesList.name = this.name
        preFill.logInstancesList.filterInMilliseconds = this.filterInMilliseconds
        preFill.logInstancesList.options = { ...this.options, page: 1 }

        localStorage.preFillData = JSON.stringify(preFill)
      }
    },
    loadPreFill() {
      this.lock = false
      this.savePreFill()
      this.sendQuery()
    },
    sendQuery() {
      this.$emit('fetchLogs', { options: this.options, name: this.name, filterInMilliseconds: this.filterInMilliseconds })
      this.savePreFill()
    }
  }
}
</script>
<style lang="scss" scoped>
.clickable {
  cursor: pointer;
  text-decoration: underline;
  color: var(--v-primary-base);
}
</style>
